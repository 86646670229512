.Phase1Map {
  background: url("../../../images/map.png") #FFF no-repeat center center;
  background-size: 100%;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.Phase1Map .place {
  position: absolute;
  transform-origin: top left;
  backface-visibility: hidden;
  cursor: no-drop;
}
.Phase1Map .place:hover { z-index: 1; }
.Phase1Map .place.notified:hover { cursor: pointer; }


.Phase1Map .place .inactive_img { display: block;}
.Phase1Map .place .active_img { display: none;}


.Phase1Map .place.unlocked .inactive_img,
.Phase1Map .place:hover    .inactive_img { display: none;}
.Phase1Map .place.unlocked .active_img,
.Phase1Map .place:hover    .active_img { display: block;}
.Phase1Map .place.unlocked .place-name,
.Phase1Map .place:hover    .place-name { display: block;}


.Phase1Map .place .place-name{
  display: none;
  position: absolute;
  white-space: nowrap;
  top: 100%;
  left: 50%;
  background: var(--black);
  color: #FFF;
  padding: 10px 40px;
  /* padding-left: 50px; */
  border-radius: 99px;
  text-align: center;

  transform-origin: center center;
}
.Phase1Map .place .place-name::after{
  content: "";

  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%) translateY(-2px);

  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 7.5px 0 7.5px;
  border-color: var(--black) transparent transparent transparent;

}

.Phase1Map .place.notified .place-name { padding-left: 50px;}
.Phase1Map .place .place-name span {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.Phase1Map .place .place-name .profile-pic {
  width: 50px;
  height: 50px;

  position: absolute;
  left: -10px;
  top: 50%;
  border-radius: 50%;
  border: 3px solid var(--black);
  background-color: var(--black);
  transform:  translateY(-50%);
  background-size: contain;

  font-size: 40px;
  line-height: 40px;
  color: #000;
  font-weight: bold;
  text-shadow: 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;

  filter: grayscale(1);
}

.Phase1Map .place.notified .place-name .profile-pic { filter: grayscale(0); }

.trees {
  z-index: 3;
  position: relative;
  width: 100%;
  height: 100%;

  background: url("../../../images/map_trees.png") no-repeat center center;
  background-size: 100%;

  pointer-events: none;
}