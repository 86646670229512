.Category{
  /* border: 1px solid  red; */
  margin: 10px 0;
}

.Category > h3{
  margin: 0;
  margin-right: 50px;
  /* padding: 10px 0; */
  padding-left: 30px;

  height: 50px;

  display: flex;
  align-items: center;

  justify-content: space-between;

  border-radius: 0 999px 999px 0;
  border: 3px solid #c4c2bb;
  border-left: none;
}
.Category > h3 .description-btn{
  border: 3px solid  #FFF;
  background: #000;
  color: #FFF;
  min-width: 40px;
  min-height: 40px;
  margin-right: 5px;
  border-radius: 50%;

  font-size: 30px;
  line-height: 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

.Category > h3 .description-btn:hover{
  background: #FFF;
  color: #000;
}

.Category.selected > h3 {
  color: #FFF;
  background: var(--black);
  border-color: var(--black);
}
.Category.selected > h3::before { background-position: center right;}


.Category .description-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 2;

}
.Category .category-description {
  position: absolute;
  background: #FFF;
  color: #000;
  padding: 30px;
  z-index: 2;

  max-width: 60%;

  border-radius: 20px;
}


.Category .category-description .close{
  position: absolute;
  top: 10px;
  right: 10px;
  border: 1px solid black;
  width: 20px;
  height: 20px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
}

.Category .category-description .close:hover {
  background: #000;
  color: #FFF;
}