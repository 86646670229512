.Ph1SkipDialsPopup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;
}




.Ph1SkipDialsPopup {
  max-width: 60%;
  background: #FFF3D1;
  padding: 30px;
  border: 5px solid  #000;
  border-radius: 30px;
  box-shadow: var(--shadow);

}

.Ph1SkipDialsPopup .Ph1SkipDialsPopup-buttons{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.Ph1SkipDialsPopup .Button{
  padding: 12px 20px;
  /* width: 100%; */
  font-size: 20px;
  font-weight: bold;
  white-space: nowrap;
  margin: 10px 15px;
}