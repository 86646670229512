.ProjectCard {
  border: 7px solid black;
  border-radius: 30px;
  width: 25%;
  max-width: 450px;
  margin: 10px;
  box-shadow: var(--shadow);
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  transition: transform 0.4s, background-color 0.4s;
  position: relative;
  overflow: hidden;
  background: #fee8cc;

  cursor: pointer;
}

.ProjectCard.locked{
  background: #d2d2d2;
  color: #525252;
}

.ProjectCard.selected{
  z-index: 2;
  background: var(--lightblue);
  transform: scale(1.2);
}

.ProjectCard h1,
.ProjectCard h2,
.ProjectCard h3{
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  padding: 0 15px;
  margin: 5px 0;
}

/* .ProjectCard h1 { font-weight: 900; font-size: max(2.5vw, 15px); }
.ProjectCard h2 { font-weight: 500; font-size: max(1.7vw, 14px);}
.ProjectCard h3 { font-size: max(1vw, 12px);} */

.ProjectCard h1 { font-weight: 900; font-size: 36px; }
.ProjectCard h2 { font-weight: 500; font-size: 26px;}
.ProjectCard h3 { font-size: 23px;}
.ProjectCard .description { font-size: 18px; text-align: justify; padding: 0 40px;}
/*

@media screen and (max-width: 1150px) {
  .ProjectCard h1 { font-weight: 900; font-size: 1.5em; }
  .ProjectCard h2 { font-weight: 500; font-size: 1em;}
  .ProjectCard h3 { font-size: 1em;}
} */



.ProjectCard .description{
  padding: 0 15px;
  font-weight:300;
  margin: 20px 0;
  flex-grow: 1;
  overflow-y: auto;
  position: relative;
  z-index: 1;
}


.ProjectCard.project .description{
  margin: 20px;
  border-radius: 20px;
  padding: 20px;
  background: rgba(97, 97, 97, 0.1);
  z-index: 0;
}


@media (max-width: 800px) {}


.ProjectCard.project svg{ display: none; }

.ProjectCard svg{
  fill: #fcceac;
  stroke: #000;
  stroke-width: 1.5px;
  position: absolute;
  bottom: -10px;
  left: -10px;
  right: -10px;
}
.ProjectCard.selected svg{ fill: #9ac0d4; }
.ProjectCard.locked svg { fill: #b3b3b3; }

.ProjectCard img.character{
  position: absolute;
  bottom: 0;
  right: 0;
  height: 50%;
  width: auto;
  max-width: none;
  max-height: 250px;
}


.ProjectCard img.character{ filter: brightness(0.9) contrast(1.2) sepia(0.9) hue-rotate(342deg); }
.ProjectCard.selected img.character{ filter: brightness(0.9) contrast(1.2) sepia(1) hue-rotate(167deg); }
.ProjectCard.locked img.character{ filter: brightness(0.9) contrast(1.2) saturate(0); }


.ProjectCard img.cadenas{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)  translateY(-50%);
  width: 60%;

  opacity: 0.3;

}