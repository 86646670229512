.Header {
  padding: 20px 40px;
  background: #585c59;
}
.Header .Header-text {
  color: #2fcfe0;
  font-size: 1.2em;
  margin-left: 20px;
}
.Header .Header-text span{
  font-weight: bold;
  margin-left: 10px;
}
.Header .progress-ctnr{

  height: 40px;
  width: calc(100% - 100px);
  border-radius: 999px;
  background: #fff5e0;
  border:3px solid var(--black);
  box-shadow: var(--shadow);

  overflow: hidden;
}



.Header .progress-bar {
  width: 100%;
  height: 100%;
  background: #2fcfe0;
  border-radius: 999px;
  box-shadow: 0 0 0 3px var(--black);
  position: relative;
  transform: translateX(-150px);
  transition: width 2s;

}

.Header .progress-bar::before {
  content: "";
  position: absolute;
  background: #94eae7;
  top: 5px;
  left: 165px;
  right: 15px;
  height: 10px;

  border-radius: 999px;
}