.QuizScreen{
  height: 100%;
  /* padding: 10px 20px; */
  overflow-y: auto;

  background: url("../../../images/fond_map_bleu.jpg") no-repeat center center;
  background-size: cover;

  display: flex;
  justify-content: space-between;
}

.QuizScreen > .quiz-col-2 { flex-grow: 1; }
.QuizScreen > .quiz-col-1,
.QuizScreen > .quiz-col-3 {
  min-width: 10px;
  max-width: 10px;
  padding: 20px;
}

.QuizScreen .quiz-title {
  width: 20%;
  position: absolute;
}
.QuizScreen .quiz-ctnr {
  max-width: 860px;

  margin: 0 auto;
  padding-top: 40px;
  padding-right: 30px;
  padding-left: 30px;

  display: flex;
  flex-direction: column;
}

.QuizScreen .QuizScreen-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 50px;
}

.QuizScreen .score{
  color: var(--black);
  font-weight: bold;
  font-size: 1.8em;
}

.QuizScreen .score span{
  font-size: 2em;
  color: #043A52;
  font-weight: 900;
}

.QuizScreen .final-text,
.QuizScreen .question-text{
  background: var(--lightblue);
  box-shadow: var(--shadow);
  border-radius: 40px;
  border: 5px solid #000;
  font-size: 1.4em;
  padding: 20px;
  margin: 0 50px;
  margin-bottom: 10px;
  white-space: pre-wrap;
}
.QuizScreen .question-text.feedback { background: var(--grey2); }

.QuizScreen .question-images {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.QuizScreen .question-images img{
  max-height: 260px;
  margin: 0 20px;
  margin-bottom: 10px;

  border: 5px solid  var(--black);
  border-radius: 50px;
}


.QuizScreen .question-choices{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px 40px ;
  margin: 20px 0;
}


.QuizScreen .choice-text {
  font-weight: bold;
  font-size: 22px;
  margin: 0;
}
.QuizScreen .choice-text:hover{
  background:#043A52;
  color:var(--lightblue);
  filter: none;
}
.QuizScreen .choice-text.inactive{
  cursor: auto;
  background: var(--darkblue);
  pointer-events: none;
}
.QuizScreen .choice-text.correct{
  background: #BEE0BD;
  color: var(--black);
}

.QuizScreen .choice-text.wrong{
  background: #F69697;
  color: var(--black);
}

.QuizScreen .QuizScreen-end {
  /* border: 1px solid  red; */
  flex-grow: 1;

  display: flex;
  align-items: center;

}

.QuizScreen .QuizScreen-end .score {
  font-size: 15vw;
  font-weight: 700;

  width: 100%;
  border: 10px solid #000;
  border-radius: 60px;
  background: var(--lightblue);
  text-align: center;

}
.QuizScreen .QuizScreen-end .score span { font-size: 1em; }


.QuizScreen .QuizScreen-bottom {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 30px;
}

.QuizScreen .QuizScreen-bottom .button-next .Button {
  padding: 20px;
  width:  100px;
  height: 100px;
}
