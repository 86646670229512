.CardsColumn {
  height: 100%;
  overflow: hidden;
  min-width: 300px;
  max-width: 300px;
  margin-left: 20px;
}
.CardsColumn-content {
  display: flex;
  flex-direction: column;

  overflow-y: auto;
  height: 100%;

  padding:10px 20px;

  background: #F0EDEB;

}

.CardsColumn .CardsColumn-content > * { margin-bottom: 20px; }
.CardsColumn .CardsColumn-content > *:last-child { margin-bottom: 20px; }