.Messages {
  flex-grow: 1;

  display: flex;
  flex-direction: column;


  background: #F0EDEB;
  padding: 15px;
  padding-right: 25px;
}

.Messages .Messages-content *{ overflow-anchor: none;}
.Messages .Messages-content{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  user-select: none;
  position: relative;

}

/* pour coller les messages en bas */
.Messages .Messages-content::before {
  content: "";
  flex-grow: 1;
}
