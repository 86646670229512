.btn{
  position: relative;
  border: none;
  font-size: 1.2em;
  padding: 6px 20px;
  color: #fff;
  cursor: pointer;
  border-radius: 99px;
  background: var(--lightgreen);
  outline: none;

  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;

  user-select: none;
}
.btn:hover .tip{opacity: 1;}
.btn:hover{ background: var(--bcg-green); }
.btn:focus{ box-shadow: 0 0 0 3px var(--darkgrey); }
.btn:active{ box-shadow: 0 0 0 3px var(--darkgrey); }

.btn:disabled{
  background: #CCC;
  cursor: no-drop;
}

.btn.grey {
  background: var(--grey);
  color: #000;
}

.btn.grey:hover{ background: var(--darkgrey); color: #FFF;;}
.btn.grey:focus{ box-shadow: 0 0 0 3px var(--darkgrey); }
.btn.grey:active{ box-shadow: 0 0 0 3px var(--darkgrey); }
.btn .icon { margin-right: 10px; }


.icon-btn.big {
  width: 70px;
  height: 70px;
}
.icon-btn {
  background: var(--lightgreen);
  border: none;

  width: 40px;
  height: 40px;
  font-size: 25px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #FFF;
  border-radius: 50%;



  cursor: pointer;
  outline: none;
  transition: all 0.2s;

}
.icon-btn:hover { background: var(--bcg-green);}
.icon-btn:active { background: transparent; color: var(--lightgreen);}

.icon-btn.whitered { background: #FFF; color: var(--red); }
.icon-btn.whitered:hover { background: var(--grey2);}
.icon-btn.whitered:active { background: transparent; color: #FFF;}

.icon-btn.grey { background: var(--grey2); color: var(--grey3); }
.icon-btn.grey:hover { background: var(--grey3); color: #FFF; }
.icon-btn.grey:active { background: transparent; color: var(--grey3);}





.value{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  min-width: 20px;
  height: 20px;
  text-transform: uppercase;
  color: #FFF;
  background: var(--darkgold);
  font-weight: bold;
  border-radius: 3px;
  box-shadow: 2px 2px 0 rgb(0, 0, 0, 0.3);
}
.value span{

  text-transform: capitalize;
  padding: 0 5px;
}

.value.participation { background: red; }
.value.inclusion { background: green; }
.value.sustainability { background: rgb(224, 224, 37); color: rgb(101, 105, 45); }
.value.quality { background: blue;}
.value.complexity { background: var(--darkgold);}




.app-title {
  font-size: 30px;
  color: var(--bcg-green);
  font-weight: bold;
  white-space: nowrap;
}
.app-title img {
  width: 80px;
  height: auto;
  margin-right: 20px;
}


.Button.enter-button {
  width: 90px;
  height: 90px;
  padding: 15px;
  margin: 0;
}