.Map {
  height: 100%;
  position: relative;

  transition: padding 0.5s;
}
.Map .BurgerMenu > .Button{ background: #BFDFBE; }

.Map #map-ctnr { height: 100%; }



.Map .SidePanel {
  z-index: 998;
  padding: 10px;

  /* transform: translateX(-410px); */
}

.Map .SidePanel { width: 550px; }


.Map .SidePanel .SidePanel-content { padding: 10px;   }


.Map .SidePanel .map-panel-close:hover {color: var(--darkblue);}
.Map .SidePanel .map-panel-close {
  width: 50px;
  height: 50px;
  font-size: 40px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  right: 10px;
  top: 10px;

  cursor: pointer;

}
.Map .SidePanel .marker-location {
  margin: 20px 0;
  /* punaise_text.svg */
  background: url('../../../images/punaise_text.svg') left center no-repeat;
  background-size: 40px;

  padding-left: 50px;
}
.Map .SidePanel .marker-location h1 {
  margin: 0;
  font-weight: 900;
  font-size: 45px;
}
.Map .SidePanel .marker-location h3 {  margin: 0; }

.Map .SidePanel h2 {
  margin: 0;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 30px;
  margin-bottom: 20px;
}



.Map .SidePanel .description { white-space: pre-wrap; }

.Map .SidePanel .logo { max-height: 100px; }
.Map .SidePanel .image { max-height: 350px; margin: 0 auto; display: block; }

.Map .BurgerMenu {
  z-index: 999;
}
.Map .map-backbutton {
  position: absolute;
  top: 0;
  right: 30px;

  font-size: 1.2em;
  padding: 20px 40px;


  z-index: 999;
}


.map-marker {
  background: var(--lightblue);
  border-radius: 50%;
  border: 2px solid #161E1D;
}
.map-marker.active {
  background: #c91459;

}