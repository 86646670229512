.ImagePopup-ctnr {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: rgba(0, 0, 0, 0.4);

  display: flex;
  justify-content: center;
  align-items: center;
}
.ImagePopup {

  height: 70%;
  width: 80%;

  padding: 40px;
  border: 5px solid  #000;
  border-radius: 30px;
  background: var(--lightblue);
  box-shadow: var(--shadow);

  display: flex;
  justify-content: center;
  align-items: center;



  position: relative;
}
.ImagePopup .ImagePopup-close {
  position: absolute;
  margin: 0;
  background: #F69697;
  top: -40px;
  right: -40px;
  width: 80px;
  height: 80px;

  padding: 10px;

  display: flex;
  justify-content: center;
  align-items: center;


}
.ImagePopup img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}