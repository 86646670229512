.Boussole {
  height: 140px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Boussole > * {
  top: 50%;
  left: 50%;
  position: absolute;
}

.Boussole .inclusion { transform: translateX(-50%)  translateY(-111%) translateZ(0) ;}
.Boussole .sustainability { transform: translateX(-111%)  translateY(-50%) translateZ(0) ;}
.Boussole .participation { transform: translateX(11%)  translateY(-50%) translateZ(0) ;}
.Boussole .quality { transform: translateX(-50%)  translateY(11%) translateZ(0) ;}
