.Wrapup {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background: url('../../../images/fond_map.jpg') no-repeat;
  background-size: cover;
}

.Wrapup .wrapup-totals {
  padding: 10px;
  background: #FFF;
  margin-bottom: 10px;
}

.Wrapup .carousel {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  margin: 0 auto;
  position: relative;
}


.arrow {
  position: absolute;
  top: 50%;

  background: #000;
  color: #FFF;
  width: 50px;
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;

  border-radius: 50%;

  z-index: 1;
}

.arrow.next { right: 20px; }
.arrow.prev { left: 20px; }

.Wrapup .carousel-track {
  display: flex;
  width: 100%;
  height: 100%;

  overflow-x: hidden;
  scroll-snap-type: x mandatory;
}


.Wrapup .carousel .slide{
  min-width: 100%;
  scroll-snap-align: start;
  margin: auto;
}

.Wrapup .carousel .slide .slide-popup{
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 60%;
  margin: 0 auto;
  background: #FFF3D1;
  border: 6px solid #000;
  border-radius: 30px;
  box-shadow: var(--shadow);
}
.Wrapup .carousel .slide .slide-popup .Button{ padding: 10px 20px; }




/* .Wrapup .period-value { display: flex; } */
.Wrapup .period-value .period-value-name { flex-grow: 1; margin-right: 10px;}
.Wrapup .period-value .period-value-val { font-weight: bold; color: var(--lightgreen);}

.Wrapup .wrapup-card-ctnr {
  max-width: 500px;
  margin: 0 auto;
}

.Wrapup .wrapup-card-debrief {
  margin: 20px 0;
}
.Wrapup .combos {
  margin-bottom: 20px;
}


.Wrapup .discount small { display: block; }



@media (max-width: 500px) {
  .Wrapup { flex-direction: column-reverse; padding: 20px;}
  .Wrapup .col1 { margin-top: 20px; margin-left: 0;}
}


.finish-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
}