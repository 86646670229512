.LoginScreen {
  background: url("../../../images/fond_map.png") no-repeat center center;
  background-size: cover;

  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-between;

  height: 100%;

  overflow-y: auto;
}

.LoginScreen .login-top { width: 100%; padding: 30px; }
.LoginScreen .login-top img{ height: 200px; }


.LoginScreen .login-center {
  margin: 0 auto;
  max-width: 600px;
  padding: 0 40px;
}


.LoginScreen .facebook-btn::before,
.LoginScreen .google-btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 60px;
  background: url("../../../images/menu/google_btn.png")rgba(0, 0, 0, 0.2) center center no-repeat;
  background-size: auto 30px;
}
.LoginScreen .facebook-btn::before { background-image: url("../../../images/menu/facebook_btn.png"); }

.LoginScreen .facebook-btn,
.LoginScreen .google-btn {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 80px;
  padding-right: 30px;
  background: #F69696;
  font-weight: bold;
  margin-bottom: 20px;
}
.LoginScreen .facebook-btn { background: #96c0f6; }


.LoginScreen .separator::before,
.LoginScreen .separator::after {
  content: "";
  border-bottom: 1px solid  #000;
  flex-grow: 1;
  height: 1px;
}


.LoginScreen .separator {
  display: flex;
  align-items: center;
  font-size: 1.5em;
  gap: 5px;
  margin: 10px -20px;

}


.LoginScreen form .Button{
  margin: 0 auto;
  margin-top: 20px;
}


.login-after-form {
  /* border: 1px solid  red; */
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 1.1em;

}
.login-after-form a{
  color: #0D4056;
  font-weight: bold;
}

.LoginForm .LoginForm-error {
  font-weight: bold;
  color: rgb(226, 58, 58);
  font-size: 1.1em;
}