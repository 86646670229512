.SideCategory{
  padding-left: 10px;
  cursor: pointer;
  display: flex;
  overflow: hidden;

  position: relative;

}

.SideCategory.notified { background: #fcdec5; }

.SideCategory.notified::after {
  content: "";
  width: 22px;
  height: 22px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform:  translateY(-50%);
  border-radius: 50%;
  background: #FE4B4E;
  box-shadow: 2px 2px 0 0px var(--black);

  border: 2px solid  #000;
}

.SideCategory.selected {
  background: var(--black);
  color: #fff5e0;
}


.SideCategory .SideCategory-linebubble {
  min-width: 50px;
  margin-right: 10px;
  position: relative;
}

.SideCategory .SideCategory-linebubble .line {
  position: absolute;
  left: 50%;
  transform: translateX(-50%) ;
  top: 0;
  height: 100%;
  width: 3px;
  background: #000;
}
.SideCategory .SideCategory-linebubble .bubble {
  position: relative;
  margin: auto;
  margin-top: 5px;
  width: 40px;
  height: 40px;

  border-radius: 50%;

  border: 2px solid black;
  border: 3px solid var(--black);
  box-shadow: var(--shadow-sm);
  background: #fff5e0;

  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-align: center;
  font-size: 22px;
  line-height: 22px;
}


.SideCategory:first-of-type .SideCategory-linebubble .bubble { margin-top: 0; }
.SideCategory .bubble.checked { background:#96d12f; }




.SideCategory .SideCategory-linebubble {
  display: flex;
  justify-content: center;
  align-items: center;
}

.SideCategory .icon {
  position: relative;
  fill: #000;
  width: 40px;
  height: 40px;
}
.SideCategory.selected .icon { fill: #FFF; }







.SideCategory .SideCategory-content { overflow: hidden; padding-bottom: 10px; }
.SideCategory .SideCategory-content .SideCategory-title {display: flex; padding-top: 5px;}
.SideCategory .SideCategory-title h3 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 5px 0;
}

.SideCategory .SideCategory-content .SideCategory-title .icon { width: 30px; height: 30px; margin-right: 10px;}

.SideCategory .cards-left {
  display: inline-block;
  margin-bottom: 5px;
  padding: 2px 10px;
  border-radius: 99px;
  background: #af6a4f;
  color: #fff5e0;
  font-size: 0.9em;
  text-transform: uppercase;
  font-weight: 700;
}
.SideCategory.completed .cards-left {
  background: grey;
}



.SideCategory .cards-minimap span {
  display: inline-block;
  width: 30px;
  height: 30px;

  margin-right: 10px;
  margin-bottom: 10px;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  background: #fff5e0ff;
  border-radius: 50%;
  border: 3px solid  var(--black);
  box-shadow: 3px 3px 0 0px var(--black);
}

.SideCategory .cards-minimap .minimap-plus {
  border-color: #af6a4f;
  color: #af6a4f;
  box-shadow: 3px 3px 0 0px #af6a4f;
}

.SideCategory .cards-minimap .minimap-check {
  border-color: #96d12f;
  color: #96d12f;
  box-shadow: 3px 3px 0 0px #96d12f;
}

.SideCategory .cards-minimap .minimap-lock {
  border-color: #cc322d;
  color: #cc322d;
  box-shadow: 3px 3px 0 0px #cc322d;
}
.SideCategory .cards-minimap .minimap-ban {
  border-color: #585c59;
  color: #585c59;
  box-shadow: 3px 3px 0 0px #585c59;
}


.SideCategory .minimap-card {
  display: inline-block;
  width: 26px;
  height: 20px;
  margin-right: 4px;
  border-radius: 3px;
}

.SideCategory .minimap-card.slot {
  background: #DCD9D6;
  border-left: 1px solid #C1BEBC;
  border-top: 1px solid #C1BEBC;
}

.SideCategory .minimap-card.unlocked {
  background: var(--black);
  border-right: 1px solid #C1BEBC;
  border-bottom: 1px solid #C1BEBC;
 }