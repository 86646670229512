.Newspaper {
  width: 90%;
  margin: 30px auto;
  margin-top: 120px;

  background: url(../../../images/journal/journaltexture_carre.jpg);
  padding: 30px 45px;
  position: relative;
}

.Newspaper .Newspaper-sheets {
  position: absolute;
  bottom: 100%;
  left: 110px;
  right: 110px;
  height: 93px;
  background: url(../../../images/journal/milieu.png);
}

.Newspaper .Newspaper-sheets::before,
.Newspaper .Newspaper-sheets::after {
  content: "";
  position: absolute;
  left: -93px;
  width: 93px;
  top: 0;
  height: 100%;
  background: lime;
  background: url(../../../images/journal/cote_gauche.png);


}

.Newspaper .Newspaper-sheets::after {
  background: url(../../../images/journal/cote_droit.png);
  right: -97px;
  width: 97px;
  left: auto;

}

.Newspaper .Newspaper-title {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Newspaper .Newspaper-title img { margin: 0 10px; }
.Newspaper .Newspaper-title .Newspaper-title-stripes {
  display: block;
  border-top: 4px solid #5B5B5B;
  border-bottom: 4px solid #5B5B5B;
  align-self: stretch;
  flex-grow: 1;
  margin: 2px 0;

  position: relative;
}
.Newspaper .Newspaper-title .Newspaper-title-stripes::before {
  content: "";
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
  height: 4px;
  background: #5B5B5B;
}


.Newspaper .Newspaper-content {
  display: flex;
  padding-top: 50px;
}

.Newspaper .Newspaper-content .Newspaper-left {
  max-width: 55%;
  min-width: 55%;
  padding-right: 50px;
  margin-right: 50px;
  border-right: 4px solid #5B5B5B;
}



.Newspaper .Newspaper-content h1 {
  margin-top: 0;
  color: #363636;
  padding-bottom: 10px;
  border-bottom: 4px solid #5B5B5B;
}
.Newspaper .Newspaper-content .Newspaper-castle-picture {
  border: 6px solid  #3B3B3B;
  max-width: 838px;
  margin: 0 auto;
  aspect-ratio: 838 / 471;
}
.Newspaper .Newspaper-content .Newspaper-castle-picture img{ display: block;}

.Newspaper .Newspaper-content .Newspaper-right { flex-grow: 1; }



/* @media screen and (max-width: 1250px) {
  .Newspaper .Newspaper-content .Newspaper-left {
    max-width: 35%;
    min-width: 35%;
  }
} */
@media screen and (max-width: 1050px) {
  .Newspaper .Newspaper-title img { max-height: 50px;}
  .Newspaper-content {
    flex-direction: column;
  }
  .Newspaper .Newspaper-content .Newspaper-castle-picture{
    display: block;
    max-height: 300px;
  }

/* } */
/* @media screen and (max-width: 900px) { */
  .Newspaper .Newspaper-content .Newspaper-left {
    max-width: none;
    min-width: none;
    border-right: none;
    margin: 0;
    padding: 0;

  }

}
