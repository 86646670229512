button.Button{
  margin: 10px 0;
  display: block;

  background: var(--lightblue);
  color: var(--black);
  cursor: pointer;
  box-shadow: var(--shadow);
  padding: 30px;
  font-size: 1.8em;
  border-radius: 40px;
  border: 5px solid #000;

}

button.Button.pill { border-radius: 999px; }


button.Button:hover { filter: brightness(0.8); }

button.Button.small-shadow { box-shadow: var(--shadow-sm); }
button.Button.small-shadow:active { transform: translateX(4px) translateY(4px); }

button.Button:active {
  transform: translateX(8px) translateY(8px);
  box-shadow: none;
}

button.Button:disabled {
 text-decoration: line-through;
 color: gray;
 background:darkgray ;
}