.phase2 {
  height: 100%;
  display: flex;

  background: grey;
  overflow-y: hidden;
}

.phase2 .BurgerMenu > .Button{ background: #FEF5E2; }


.phase2.col-left-visible .col2{
  margin-left: 400px;
  transition-duration: 0.6s;

}


.phase2 .SidePanel h2 {
  margin: 20px;
  color: var(--black);
}

.phase2 .SidePanel .SideCategory  { margin-bottom: 5px; }

.phase2 .col2 {
  flex-grow: 1;
  overflow-y: hidden;

  display: flex;
  flex-direction: column;
  background: #FDF9F5;

  margin-top: 40px;
  transition: margin 0.4s;
}

.phase2 .col2 h3 {
  height: 70px;
  min-height: 70px;
  max-height: 70px;
  background: var(--black);
  color: #FFF;
  padding-left: 40px;
  margin-bottom: 0;
  display: flex;
  align-items: center;

  font-size: 25px;
  margin-top: 0;

  white-space: nowrap;
}
.phase2 .col2 h3 span{
  margin: 0 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.phase2 .col2 h3 span.long-title{
  font-size: 20px;
  font-weight: normal;
  color: #7e7e7e;

  padding: 10px;

  flex-shrink: 1000000; /* permet de ne shrink le gros titre que si vraiment nécessaire */


  /* border: 1px solid  red; */
  border-left: 2px solid #7e7e7e;
}


.phase2 .col2 h3 .icon{

  margin-right: 20px;
  fill: white;
  width: 40px;
  height: 40px;
}



.phase2 .col2 .col2-bottom{
  flex-grow: 1;

  display: flex;
  overflow: hidden;
  margin: 15px;
  margin-left: 23px;
  margin-top: 0;
}

.phase2 .col2 .col2-bottom > * {
  border-radius: 0 0 30px 30px;
}



/*********************/


.popup-passage-ph3-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: rgba(0, 0, 0, 0.226);

  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-passage-ph3 {
  background: #FFF;
  padding: 40px;
  border-radius: 30px;
  border: 8px solid var(--black);
  box-shadow: var(--shadow);
}
.popup-passage-ph3 .ph3-btn{
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

@media screen and (max-width: 1200px) {
  .phase2 .SidePanel {
    width: 300px;
  }
  .phase2.col-left-visible .col2{
    margin-left: 300px;
    transition-duration: 0.6s;

  }
}