.CardPh2 {
  height: 150px;

  border-radius: 30px;

  margin-bottom: 20px;

  display: flex;
  justify-content: center;
  align-items: stretch;


  font-weight: bold;

  background: #000;
  color: #FDE8D3;
  /* box-shadow: var(--shadow); */
  box-shadow: 8px 8px 0 0px #C1BEBC;


}
.CardPh2.empty {
  border: 5px solid  #C1BEBC;

  box-shadow: 8px 8px 0 0px #C1BEBC;
  background: #DCD9D6;
}

.CardPh2 .title {
  flex-grow: 1;

  /* width: 100%; */


  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;


  border: 1px solid #FDE8D3;

  margin: 20px;
  padding: 10px;
}





.CardPh2.empty .title{
  border: none;
  color: #C1BEBC;
}
.CardPh2.empty .title-big{
  font-size: 80px;
}