.ChooseSavegamePopup-overlay {
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
background: rgba(0, 0, 0, 0.534);

display: flex;
justify-content: center;
align-items: center;

}
.ChooseSavegamePopup {
  margin: 30px;
  padding: 40px 30px;
  background: #FFF;
  border-radius: 30px;
  border: 6px solid #000;
  box-shadow: var(--shadow);
}

.ChooseSavegamePopup .ChooseSavegamePopup-buttons {
  /* border: 1px solid  red; */
  margin-top: 10px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.ChooseSavegamePopup .btn-ctnr {
  margin: 10px;
}
.ChooseSavegamePopup .Button {
  font-size: 16px;
  padding: 5px 20px;
  box-shadow: none;
  border: 3px solid #000;
  font-weight: bold;
  margin: 0;
}
.ChooseSavegamePopup span{
  font-size: 0.8em;
}