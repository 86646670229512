.Message{
  margin: 10px 0;
  padding: 10px;
  margin: 10px 0;
  position: relative;
}

.Message .Message-name{
  margin: 0 100px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #6D9B94;
}
.Message.right .Message-name{ text-align: right; }


.Message .l2{
  display: flex;

  transform: translateX(0);
  transition: transform .5s;

  /* border: 1px solid  red; */
}
.Message.left.mounting .l2{ transform: translateX(-70%); }
.Message.right.mounting .l2{ transform: translateX(70%); }
.Message.right .l2{ flex-direction: row-reverse; }


.Message.right .Message-pic { transform: scaleX(-1); }
.Message .Message-pic {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 3px solid white;
  background-size: cover;
  z-index: 1;
}


.Message .Message-text {
  display: block;
  background: #888;
  color: #FFF;
  padding: 20px 30px;
  text-align: center;
  border-radius: 30px;
  margin: 0;
  width: 100%;
}


.Message.right .Message-text,
.Message.left .Message-text {
  width: auto;
  max-width: 60%;
  display: inline-block;
  color: #000;
  text-align: left;
}


.Message.left .Message-text {
  padding-left: 70px;
  transform: translateX(-40px);
}
.Message.right .Message-text {
  text-align: right;
  transform: translateX(40px);
  padding-right: 70px;
}


.Message .Message-choices {
  padding: 5px 0;
  /* display: flex;
  flex-direction: column;
  align-items: flex-end; */
}

.Message .Message-choices .choice {
  border: none;
  background: var(--black);
  color: #FFF;
  font-size: 1em;
  padding: 10px 15px;
  border-radius: 99px;
  font-weight: bold;
  text-align: right;
  margin: 5px 0;


}
.Message .Message-choices .choice:hover {
  opacity: 0.8;
  cursor: pointer;

}
.Message .Message-choices button:disabled {
  background: rgba(0, 0, 0, 0.4);
  color: rgb(189, 189, 189);
}
.Message .Message-choices button.choice.answered {
  border: 3px solid white;
  background: rgba(0, 0, 0, 0.637);
  color: #FFF;
}

.Message .Message-cards {
  /* border: 1px solid  red; */
  display: flex;
  flex-wrap: wrap;

  padding: 20px;
  padding-bottom: 0;
  transform: translateX(0%);
  transition: transform 1s;
}

.Message.right .Message-cards { justify-content: flex-end; }



.Message .Message-cards .Message-card { cursor: pointer; }
.Message .Message-cards .Message-card.picked {
  opacity: 0.3;
  filter: grayscale(90%);
}


.Message .Message-cards .CardPh2 { cursor: pointer; }
.Message .Message-cards .CardPh2.empty { cursor: auto; }


/********** characters */

.Message.system {
  margin: 0;
  padding: 0;
  font-weight: bold;
}
.Message.system .Message-text {
  padding-top: 5px;
  padding-bottom: 5px;
}


/* ------------DOT ANIMATION ---------------*/

:root {
  --dot-animation-speed: .75s;
  --dot-animation-base-delay: 0s; /* same as appear animation*/
}

.dot {
  width: .5rem;
  height: .5rem;
  background: white;
  border-radius: 50%;
  content: "";
  display: inline-block;
  margin: 0.15rem;
  animation: var(--dot-animation-speed) ease-in-out var(--dot-animation-base-delay) typing-dot;
  animation-iteration-count: infinite;
}

.dot:nth-child(2) {
  animation-delay: calc(var(--dot-animation-base-delay) + var(--dot-animation-speed) * .166);
}

.dot:nth-child(3) {
  animation-delay: calc(var(--dot-animation-base-delay) + var(--dot-animation-speed) * 0.333);
}

@keyframes typing-dot {
  from {
    transform: translateY(0) scale(100%, 100%);
  }
  16.5% {
    transform: translateY(-60%) scale(95%, 105%);
  }
  33% {
    transform: translateY(0) scale(105%, 95%);
    opacity: 1
  }
  40% {
    transform: translateY(0) scale(100%, 100%);
  }
  66% {
    opacity: 0
  }
  to {
    transform: translateY(0) scale(100%, 100%);
    opacity: 1;
  }
}
