.Card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  min-height: 150px;
  text-align: center;

  padding: 20px;
  padding-top: 8px;


  overflow: hidden;
  min-width: 200px;
  max-width: 350px;

  background: #ffffff;

  border-radius: 30px;
  border: 4px solid #000;

  box-shadow: 8px 8px 0 0px #000;

  position: relative;
}

.Card .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}



.Card .card-header .card-icon {
  fill:#d1bdb1;
  width: 40px;
  height: 40px;
  /* stroke:#d1bdb1; */
  /* stroke-width: 0.5px; */
}


.Card .card-header-criterias { display: flex; }



/* .Card > *{ border: 1px solid  red;} */
.Card .card-title{
  font-weight: bold;
  color: var(--black);
  margin: 5px 0px;
  text-align: left;
  margin-top: 10px;
}

.Card .card-desc {
  flex-grow: 1;
  white-space: pre-wrap;
  margin: 5px 0px;
  margin-bottom: 10px;
  text-align: left;

  /* display: flex;
  align-items: center;

  text-align: justify;
  text-justify: none; */
}

.Card .card-bottom {
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
}

.Card .card-bottom .card-complexity span{position: relative; z-index: 1;}
.Card .card-bottom .card-complexity {
  position: absolute;
  right: 0px;
  /* bottom: 10px; */

  width:  35px;
  height: 35px;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  background: #2FCFE0;

  border: 3px solid #000;
  color: #000;
  font-weight: bold;

  white-space: nowrap;
}


.Card .card-bottom .card-complexity::before {
  content: "";
  position: absolute;
  top: 3px;
  left: 6px;
  right: 6px;
  height: 9px;
  background: rgba(255, 255, 255, 0.4);

  border-radius: 50px;
}

.Card.picked { background: var(--darkblue);  color: #fff5e0; }
.Card.picked .card-title{ color: #fff;}

.Card.toocomplex > * > *,
.Card.locked > * > * { opacity: 0.50; }

.Card.locked .card-title { opacity: 0.5; }
.Card.locked .card-desc { opacity: 0.5; }

.Card.toocomplex,
.Card.locked {
  background: rgba(255, 255, 255, 0.5);
  border-color: rgba(0, 0, 0, 0.5);
  box-shadow: 8px 8px 0 0px  rgba(0, 0, 0, 0.5);;
}



/* ??? */
.Card.negative { background: var(--lightred); }
.Card.negative .card-category { color: var(--red); }
.Card.negative .card-id{  background: var(--red); color: #FFF;}

.Card.positive { background: var(--lightgold); }
.Card.positive .card-id{  background: var(--darkgold); color: #FFF;}
.Card.positive .card-desc {  color: var(--gold);}


.Card .locking-reasons {
  position: absolute;
  padding: 20px;
  /* border: 1px solid  red; */
  background:#FFF5E0;
  color: #000;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  overflow-y: auto;

  display: flex;
  flex-direction: column;

  overflow: hidden;

}

.Card .locking-reasons h2{ margin: 0; }
.Card .locking-reasons .unlocks-tips-list{
  /* border: 1px solid  red; */
  flex-grow: 1;

  overflow-y: auto;
}



.Card .locking-reasons .card-needs{ flex-grow: 1; }



.Card .locking-reasons .UnlockTipRow .UnlockTipRow-icon {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}


.Card .locking-reasons .UnlockTipRow{
  list-style: none;
  font-weight: bold;
  text-align: left;
  margin: 5px 0;
  display: flex;
  align-items: center;
  /* border: 1px solid  red; */
}

.Card .locking-reasons .back-btn{
  margin: 0 auto;
  margin-top: 10px;
  background: transparent;
  color: #000;
  border: 2px solid #000;
  min-width: 40px;
  min-height: 40px;

  opacity: 1 !important;
}
