*, *:before, *:after {box-sizing: border-box;}


:root {
  --bcg-green: #177b57;
  --bcggreen-transparent: rgba(5, 31, 20, 0.733);
  --lightgreen: #00be71;
  --lightgreen-transparent: #00be7166;
  --grey:#EAEAEA;
  --darkgrey: #B9B9B9;
  --red: #B75C8E;
  --lightred: #FEE3F0;
  --darkred: #721760;

  --gold: rgb(196, 167, 3);
  --lightgold: #fffadb;
  --darkgold: rgb(121, 103, 1);


 --categ:  #d9eff3;



 /*FARO COLORS*/

 --grey1: #F2F2F2;
 --grey2: #EAEAEA;
 --grey3: #B9B9B9;
 --grey4: #868686;
 --grey5: #555555;

 --lightblue: #BBDEFA;
  --black: #161e1d;
  --darkblue: #375064;
  --shadow-sm: 4px 4px 0 0px #000;
  --shadow: 8px 8px 0 0px #000;
  --pink: #F39892;
}

body {
  margin: 0;
  background: var(--grey1);
  font-family: 'Roboto', sans-serif;
}

* {
  scrollbar-width: thin;          /* "auto" or "thin" */
  scrollbar-color: var(--black) #c9b499;   /* scroll thumb and track */
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

::-webkit-scrollbar {									/* Scrollbars */
	width: 5px;
	height: 14px;
	background: #c9b499 0 0 repeat;
  border-radius: 99px;
}

::-webkit-scrollbar-thumb {								/* Barre */
  background: var(--black);
  border-radius: 99px;
}

img { max-width: 100%; }


html, body, #root{
  user-select: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.App {
  height: 100%;
  min-height: 100%;
  /* transform-style: preserve-3d; */
}

.header {
  background: var(--grey);
  margin: 0;
  padding: 5px;
  width: 100%;
}

.meeting-paused,
.mobile-alert {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('../../images/no_mobile.png') rgba(255, 255, 255, 0.747) center center no-repeat;
  background-size: 60% auto;
  pointer-events: none;
  z-index: 9999;
}

.meeting-paused {
  background: #FFFFFFdd;
  display: flex;

  pointer-events: auto;
  justify-content: center;
  align-items: center;

  font-size: 2em;
}


@media screen and (min-width: 760px) {
  .mobile-alert {
    display: none;
  }
}

.version {
  position: fixed;
  bottom: 0;
  left: 0;
  font-size: 10px;
}
.debugMode {
  position: fixed;
  bottom: 0;
  right: 0;
  background: red;
  color: white;
}
