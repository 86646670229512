.ProjectChoicePopup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.438);

  display: flex;
  justify-content: center;
  align-items: center;

}

.ProjectChoicePopup .cards {
  position: absolute;
  top: 10%;
  left: 0;
  right: 0;
  bottom: 20%;


  display: flex;
  justify-content: center;
  align-items: stretch;
}
.ProjectChoicePopup .button-next{
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
}
.ProjectChoicePopup .button-prev {
  position: absolute;
  top: 30px;
  left: 30px;
}

.ProjectChoicePopup .button-prev .Button{
  padding: 20px;
  width: 120px;
  height: 120px;
}



.ProjectChoicePopup .axe{ margin: 10px; }