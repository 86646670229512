.group-code-form {
  position: absolute;
  padding-top: 10px;
}
.group-code-form .group-code-btns { display: flex; gap: 10px; }

.group-code-form .group-code-btns .group-code-cancel {
  padding: 5px 30px;
  font-size: 15pt;
  font-weight: bold;
  background: #F69696;
}

.group-code-form .group-code-btns .group-code-valid {
  width: 65px;
  height: 65px;
}

.group-code-form .group-code-btns .group-code-valid img { width: 25px; }
