.LoadingScreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 99;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #F0EBE3;


  --anim-duration: 5s;

}

.LoadingScreen > *{
  position: absolute;
  animation: pulse var(--anim-duration) infinite;
}

.LoadingScreen .loading-titles > :nth-child(1),
.LoadingScreen > :first-child {
  transform: translateX(-62%);
  animation-delay: calc(calc(var(--anim-duration) / 4) * -1);
}
.LoadingScreen .loading-titles > :nth-child(2),
.LoadingScreen > :nth-child(2) {
  transform: translateY(62%);
  animation-delay: calc(calc(var(--anim-duration) / 4) * -2);

}
.LoadingScreen .loading-titles > :nth-child(3),
.LoadingScreen > :nth-child(3) {
  transform: translateX(62%);
  animation-delay: calc(calc(var(--anim-duration) / 4) * -3);
}
.LoadingScreen .loading-titles > :nth-child(4),
.LoadingScreen > :nth-child(4) {
  transform: translateY(-62%);
}


.LoadingScreen .loading-titles {
  animation: none;

  width: 210px;
  height: 210px;
}

.LoadingScreen .loading-titles > *{
  position: absolute;
  font-weight: bold;
  font-size: 25px;
  padding: 10px 40px;
  border-radius: 999px;
  border: 4px solid #000;
  box-shadow: var(--shadow-sm);

  animation: pulse var(--anim-duration) infinite;

}
.LoadingScreen .loading-titles .loading-sustainability {
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
  background: #caea8cff;
}

.LoadingScreen .loading-titles .loading-quality {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: #9ce5d7;
}

.LoadingScreen .loading-titles .loading-participation {
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  background: #f9e491;
}

.LoadingScreen .loading-titles .loading-inclusion {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: #f1b6ff;
}

@keyframes pulse {
  from { opacity: 1;}
  to {  opacity: 0.2;}
}