.HomePhase1 {
  overflow: hidden;
  height: 100%;
}

.HomePhase1 #openfl{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
}

.HomePhase1 .loading{
  background: #FFF;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.HomePhase1 .skip-dials-btn {
  position: absolute;
  bottom: 10px;
  right: 20px;

  width: 80px;
  height: 80px;
  padding: 20px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 1;
}