.CardsList {
  margin: 0 auto;

  display: grid;

  /* permet de gérer des colonnes avec une largeur min et une largeur max, et le nombre de cols optimal est automatique  */
  grid-template-columns: repeat(auto-fit, minmax(min(290px, 330px), 1fr));
  grid-gap: 20px;
  justify-content: space-around;


  padding: 10px 20px;

}
