.Criteria {
  padding: 10px;
  padding-left: 45px;

  margin: 20px 0;
  position: relative;

  min-height: 90px;
  border: 4px solid  #5B5B5B;
  color:  #363636;

  margin-left: 30px;
  box-shadow: 5px 5px 0 0 #5B5B5B;


  display: flex;
  align-items: center;
}


.Criteria .Criteria-col1 {
  text-align: center;

  /* margin-right: 10px; */
  font-weight: bold;

  /* border: 1px solid  red; */
}

.Criteria .Criteria-col1 .val{
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateX(-50%)  translateY(-50%);
  width: 70px;
  height: 70px;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  border-radius: 50%;
  color: #FFF;
}
.Criteria.MIN .Criteria-col1 { color: #CC4C5B;}
.Criteria.MIN .Criteria-col1 .val{ background: #CC4C5B;}
.Criteria.AVERAGE .Criteria-col1{ color: #5B5B5B;}
.Criteria.AVERAGE .Criteria-col1 .val{ background: #5B5B5B;}

.Criteria.MAX .Criteria-col1{ color: #419165;}
.Criteria.MAX .Criteria-col1 .val{ background: #419165;}


.Criteria .Criteria-col2 {
  /* text-align: center; */

  flex-grow: 1;
  text-align: left;
  margin-right: 10px;

  /* border: 1px solid  blue; */
}


.Criteria.MIN {}
.Criteria.AVERAGE {}
.Criteria.MAX {}
