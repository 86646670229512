.Input-label {
  font-size: 1.3em;
  font-weight: bold;
  margin-left: 30px;
  color: #0D4056;

}
.Input {
  outline: none;
  border: 3px solid #000;
  border-radius: 99px;
  display: block;
  width: 100%;
  max-width: none;

  font-size: 1.2em;
  padding: 5px 15px;

  text-align: center;
  box-shadow: var(--shadow-sm);
  margin-bottom: 12px;
}

.Input:focus {
  border-color: #0D4056;
  box-shadow:4px 4px 0 0px #0D4056;
}