.TextPopup-ctnr {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.233);
}

.TextPopup {
  max-width: 800px;
  width: 80%;
  max-height: 80%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.TextPopup .TextPopup-close {
  width: 100px;
  height: 100px;
  position: absolute;
  top: -50px;
  right: -50px;

  background: var(--pink);
}

.TextPopup .TextPopup-content {
  background: var(--lightblue);
  box-shadow: var(--shadow);
  border: 5px solid #000;
  border-radius: 30px;
  overflow: hidden;

  display: flex;
  flex-direction: column;

}



.TextPopup .TextPopup-title {
  background: #93BCD2;
  padding: 20px;
  text-transform: uppercase;
  font-size: 25px;
  font-weight: 900;
}

.TextPopup .TextPopup-text {
  padding: 30px;
  text-align: left;
  white-space: pre-wrap;
  font-weight: bold;
  overflow-y: auto;
  margin-right: 15px;
}