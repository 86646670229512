.InlineSvg {
  width: inherit !important;
  height: inherit !important;
}
.InlineSvg svg{
  fill: inherit !important;
  stroke: inherit !important;

  width: inherit !important;
  height: inherit !important;
}

.InlineSvg svg path{
  fill: inherit !important;
  stroke: inherit !important;
}
