.CriteriaIcon {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
}


.CriteriaIcon.participation { background-image: url('../../../images/participation.svg'); }
.CriteriaIcon.inclusion { background-image: url('../../../images/inclusion.svg'); }
.CriteriaIcon.sustainability { background-image: url('../../../images/sustainability.svg'); }
.CriteriaIcon.quality { background-image: url('../../../images/quality.svg'); }


.CriteriaIcon:not(.selected) {
  filter: saturate(0.0);
  opacity: 0.7;
}

.CriteriaIcon.clickable {
  cursor: pointer;
}