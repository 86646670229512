.DebugZone {
  position: absolute;
  right: 0;
  bottom: 0;

  cursor: pointer;


  width: 15px;
  height: 15px;

  transform: translateX(14px) translateY(14px);
  transition: transform 1s;
  background: orange;
  opacity: 0.3;
  z-index: 999;
}

.DebugZone:hover {
  transform: none;
}

.DebugZone.visible {
  opacity: 1;
  width: auto;
  height: auto;
  overflow-y: auto;
  max-height: 80%;
  border: 2px solid black;
  padding: 5px;
}
