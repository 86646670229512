.BurgerMenu {
  position: absolute;
  top: 10px;
  right: 20px;

  z-index: 700;


  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 20px;
}
.BurgerMenu .BurgerMenu-btn {
  width: 80px;
  height: 80px;
  padding: 20px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;


}



.BurgerMenu .BurgerMenu-popup {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 40px;

  background: #FFF3D1;
  border: 6px solid #000;
  border-radius: 30px;
  padding: 30px;
  padding-top: 50px;
  box-shadow: var(--shadow-sm);
}
.BurgerMenu .BurgerMenu-popup .Button{
  padding: 12px 20px;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  white-space: nowrap;

}
.BurgerMenu .BurgerMenu-popup .disconnect-btn{
  background: #F79597;
}

.BurgerMenu .BurgerMenu-clock {

  box-shadow: none;
  background: #FFF3D1;
  /* color: #FFF; */
  font-weight: bold;
  padding: 5px 20px;
  font-size: 1em;

  pointer-events: none;
  /* background: #FFF; */
  /* color: #000; */

  box-shadow: var(--shadow-sm);


}