.MenuScreen {
  background: url("../../../images/fond_map.png") no-repeat center center;
  background-size: cover;

  text-align: center;

  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  overflow-y: auto;
}

.MenuScreen .menu-top {

  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  padding: 10px 20px;
}

.MenuScreen .menu-top .signin-btn{
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0;
}

.MenuScreen .menu-top .loggedin-user{
  /* border: 1px solid  red; */
  font-weight: bold;
  /* font-size: 30px; */
}
.MenuScreen .link-button,
.MenuScreen .menu-top a{
  background: none;
  border: none;
  font-size: 1em;
  text-decoration: underline;
  cursor: pointer;
  display: block;
  font-weight: bold;
  color: #043A52;
}


.MenuScreen .logo {
  max-width: 700px;
  width: 100%;
  padding: 0 40px;

  text-align: center;
}


.MenuScreen .menu-btns-ctnr{ width: 100%; }
.MenuScreen .menu-btns-strip{ background: rgba(0, 0, 0, 0.1); }
.MenuScreen .menu-btns{
  display: flex;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 10px 0;
}

.MenuScreen .menu-btn {
  width: 100%;
  display: flex;
  align-items: center;
}
.MenuScreen .menu-btn.menu-1 .Button { background-color: #bee0bf; }
.MenuScreen .menu-btn.menu-2 .Button { background-color: #bcddf4; }
.MenuScreen .menu-btn.menu-3 .Button { background-color: #dab0d0; }

.MenuScreen .menu-btn.menu-2 .Button:disabled {
  opacity: 0.6;
  pointer-events: none;
}

.MenuScreen .menu-btn .Button {
  padding: 20px 30px;
  margin: 0 auto;
  border-width: 8px;
  margin-bottom: 10px;
  transform: scale(0.9);
}
.MenuScreen .menu-btn .Button:hover {transform: scale(1); }

.MenuScreen .menu-btn img{
  display: block;
  width: 100px;
}


.MenuScreen .menu-btn .sub-btns { margin: 0 auto; }
.MenuScreen .menu-btn  .sub-btns .Button{
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 15px;
}

.MenuScreen .menu-texts{
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
}
.MenuScreen .menu-texts .menu-text{
  max-width: 250px;
  color: #395a66;
  font-weight: bold;
  margin: 0 auto;
  margin-top: 20px;
}

@media screen and (max-height: 800px) {
  .MenuScreen .menu-top .Button{
    font-size: 14px;
    border-width: 2px;
    box-shadow: var(--shadow-sm);
    padding: 3px 6px;
  }


  .MenuScreen .logo {
    max-width: 500px;
    padding: 10px 30px;
    text-align: center;
  }

  .MenuScreen .menu-btn { height: auto;}
  .MenuScreen .menu-btn .Button img{ width: 50px;}

}