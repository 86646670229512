.DialFocus {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 600;
  padding-bottom: 30px;
  display: flex;
  align-items: flex-end;
  justify-content: center;

}

.DialFocus-dial {
  max-width: 60%;
  min-width: 250px;
  margin: 0 auto;
  position: relative;
}

.DialFocus.show-choices .DialFocus-dial {
  max-width: 90%;
  margin: 0 20px;
  /* flex-grow: 1; */
}
.DialFocus.show-choices.boussole-visible .DialFocus-dial { margin-right: 190px; }


.DialFocus-dial .DialFocus-name {
  padding: 5px 20px;
  font-size: 1.3em;
  font-weight: bold;
  white-space: nowrap;

  position: absolute;
  bottom: 100%;
  left: 0;
  background: rgba(0, 0, 0, 0.521);
}
.DialFocus.champ .DialFocus-dial .DialFocus-name { color: var(--lightblue); }
.DialFocus.contrechamp .DialFocus-dial .DialFocus-name { color: #FFF5E0; }



.DialFocus.show-choices .DialFocus-dial .DialFocus-name {
  border-radius: 40px 40px 0 0;
  padding-bottom: 0px;
  margin-left: 30px;
  opacity: 1;
  padding: 10px 30px;
  left: 30px;


}



.DialFocus-dial .DialFocus-text {
  background: rgba(0, 0, 0, 0.521);
  color: #FFF;
  font-size: 24px;
  line-height: 30px;
  cursor: pointer;
  padding: 10px 20px;
  text-align: center;
}

.DialFocus.show-choices .DialFocus-dial .DialFocus-text {
  padding: 10px 30px;
  background: #bbdefa;
  color: var(--black);
  position: relative;
  box-shadow: var(--shadow);
  border-radius: 40px;
  border: 5px solid #000;
  margin: 0;
  cursor: auto;
  margin-bottom: 40px;

  /* text-align: left; */

}


.DialFocus-choices {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: -40px;
}


.DialFocus-choice {
  background: var(--black);
  padding: 10px 30px;
  border-radius: 60px;
  font-size: 21px;
  line-height: 24px;
  color: #FEF4DF;
  cursor: pointer;
  min-width: 150px;
  max-width: 350px;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;


  margin: 0 20px;
}
.DialFocus-choice:hover { background: var(--grey5); }

.DialFocus-boussole {
  position: absolute;
  right: 80px;
  height: 80px;
  bottom: 0;
  transform:  translateY(-50%);


  display: flex;
  align-items: center;
}

@media screen and (max-width: 769px) {
  .DialFocus-boussole {
    transform:  translateY(-50%) scale(0.7);
    right: 50px;
  }

  .DialFocus.show-choices.boussole-visible .DialFocus-dial {
    margin-right: 100px;
  }
}
