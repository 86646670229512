.SidePanel {
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;

  width: 400px;

  display: flex;
  flex-direction: column;
  background: #FFF5E0;

  border-radius: 0 30px 0 0;
  border-top: 3px solid black;
  border-right: 3px solid var(--black);
  box-shadow: var(--shadow);
  transition: transform 0.5s;
}

/* l'ordre de ces classes semble être important  */
.SidePanel-enter { transform: translateX(-110%)!important; }
.SidePanel-enter-active { transform: translateX(0)!important; }
.SidePanel-enter-done { transform: translateX(0)!important; }

.SidePanel-exit { transform: translateX(0)!important; }
.SidePanel-exit-active { transform: translateX(-110%)!important; }
.SidePanel-exit-done { transform: translateX(-110%)!important; }

.SidePanel .SidePanel-content {
  overflow-y: auto;
  flex-grow: 1;
}
