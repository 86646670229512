
.menu-bottom{
  width: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  padding: 20px;
  font-weight: bold;
  justify-items: start;
  align-items: end;
}

.menu-bottom a{ color: #000; }
.menu-bottom img{
  width: 30px;
  vertical-align: middle;
}
.menu-bottom .menu-bottom-center {
  justify-self: center;
  padding: 0 20px;
}
.menu-bottom .menu-bottom-center img{ min-width: 500px; }


.menu-bottom .menu-bottom-right{
  display: flex;
  gap: 10px;

  justify-self: end;


}

@media screen and (max-width: 800px), (max-height: 800px) {

  .menu-bottom .menu-bottom-center img{
    max-width: 300px;
    width: 100%;
    min-width: auto;
  }
}
@media screen and (max-width: 800px) {
  .menu-bottom{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 10px;
    gap: 10px;
  }
  .menu-bottom .menu-bottom-center {
    order:-1;
  }

}