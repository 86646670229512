.BoussolePopup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: #161E1D;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 600;

}

.crit-descriptions { position: absolute; }
.crit-descriptions > * { position: absolute; }

.crit-descriptions > .inclusion {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.crit-descriptions > .sustainability {
  right: 100%;
  top: 50%;
  transform:  translateY(-50%);
}

.crit-descriptions > .participation {
  top: 50%;
  left: 100%;
  transform:  translateY(-50%);
}

.crit-descriptions > .quality {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}


.crit-descriptions > .inclusion .crit-desc-title { background: #F1B6FF; }
.crit-descriptions > .participation .crit-desc-title { background: #F9E491; }
.crit-descriptions > .sustainability .crit-desc-title { background: #CAEA8C; }
.crit-descriptions > .quality .crit-desc-title { background: #9CE5D7; }




.crit-desc {
  background: #FFF;
  margin: 10px;
  width: 300px;
  padding: 10px;
  border-radius: 20px;
}
.crit-desc .crit-desc-title {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  padding: 5px;

  border-radius: 99px;
}


.crit-desc .crit-desc-content {
  font-size: 1.2em;
  padding: 10px;
}


.crit-desc { display: none; }
.crit-desc.visible { display: block; }
.crit-desc.notified { display: block;
  animation-name: blink;
  animation-duration: 0.8s;
  animation-iteration-count: 3;


}

@keyframes blink {
  0% {opacity: 1;}
  50% {opacity: 0;}
}
