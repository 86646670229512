.WrapupCardScreen {
  display: flex;
  height: 100%;
  width:80%;
  max-width: 1000px;
  margin: auto;
  justify-content: center;
  align-items: center;
}
.WrapupCardScreen .col-left .Card {
  margin-bottom: 20px;
  min-width: 300px;

}
.WrapupCardScreen .col-right  {
  border: 8px solid var(--black);
  border-radius: 30px;
  box-shadow: var(--shadow);
  background: #FFF5E0;

  margin-left: 20px;

  height: 80%;

  flex-grow: 1;

  position: relative;

  padding: 40px;

  margin-top: 70px;


  display: flex;
  flex-direction: column;
  justify-content: space-between;

}


.WrapupCardScreen .col-right::before  {
  content: "";
  position: absolute;
  width: 140px;
  height: 140px;
  top: 0;
  left: 50%;
  transform: translateX(-50%)  translateY(-50%);

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.WrapupCardScreen .col-right.BONUS { background: #D3FFC5; }
.WrapupCardScreen .col-right.BONUS::before  { background-image: url('../../../images/bonus_icon.png'); }

/* .WrapupCardScreen .col-right.OMISSION { background: #D3FFC5; } */
.WrapupCardScreen .col-right.OMISSION::before  { background-image: url('../../../images/omission_icon.png'); }



.WrapupCardScreen .col-right.MALUS { background: #FFD5CF; }
.WrapupCardScreen .col-right.MALUS::before  { background-image: url('../../../images/malus_icon.png'); }


.WrapupCardScreen .col-right  h1{ font-size: 50px; }
.WrapupCardScreen .col-right .wrapup-card-debrief { flex-grow: 1;}


.WrapupCardScreen .wrapup-card-criterias {
  display: flex;
  justify-content: center;

}
.WrapupCardScreen .wrapup-card-criteria {
  margin: 0 10px;
  padding: 15px;
  padding-left: 45px;
  font-weight: bold;
}

.WrapupCardScreen .wrapup-card-criteria .l1{
  position: relative;
  height: 90px;
  margin-bottom: 30px;
}
.WrapupCardScreen .wrapup-card-criteria .CriteriaIcon{
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateX(-50%)  translateY(-50%);

}




.WrapupCardScreen .wrapup-card-criteria .crit-val{
  height: 80px;
  padding: 0px 30px;
  padding-left: 50px;

  font-size: 50px;


  display: flex;
  align-items: center;
  border: 5px solid #000;
  border-radius: 40px 40px 40px 0;

  box-shadow: var(--shadow);
}


.WrapupCardScreen .wrapup-card-criteria.negative .crit-val { color: #CE1725; }
.WrapupCardScreen .wrapup-card-criteria .crit-val::after {

  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%)  translateY(50%);
  width: 40px;
  height: 40px;
  border: 5px solid #000;
  background: url("../../../images/mini_arrows.svg") #CE1725 no-repeat center center;
  background-size: auto 60%;
  border-radius: 50%;
}



.WrapupCardScreen .wrapup-card-criteria.positive .crit-val { color: #00873D; }
.WrapupCardScreen .wrapup-card-criteria.positive .crit-val::after {
  top: -5px;
  transform: translateX(-50%)  translateY(-50%) rotate(180deg);
  background-color: #00873D;
  border-radius: 50%;
}

@media screen and (max-width: 1025px) {
  .WrapupCardScreen {
    flex-direction: column;
  }
  .WrapupCardScreen .col-right  h1{ font-size: 30px; margin: 10px 0; }

}


@media screen and (max-height: 650px) {
  .WrapupCardScreen .col-right {
    padding: 20px;
    padding-top: 40px;
    border-width: 4px;
  }
  .WrapupCardScreen .col-right  h1{ font-size: 30px; margin: 10px 0; }
  .WrapupCardScreen .wrapup-card-criteria .l1{
    height: 70px;
  }
  .WrapupCardScreen .wrapup-card-criteria .CriteriaIcon {
    width: 60px !important;
    height: 60px !important;
  }
  .WrapupCardScreen .wrapup-card-criteria .crit-val{
    font-size: 20px;
    height: 60px;
    padding: 0px 30px;
    padding-left: 30px;

  }

}

/* @media screen and (max-width: 1025px), (max-height: 650px) { */
