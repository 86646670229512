.Phase3Game {
  display: flex;
  height: 100%;

  background: #585c59;
}

.Phase3Game .SidePanel{
  position: relative;
}

.Phase3Game .SidePanel h2 {
  margin: 20px;
  margin-left: 70px;
  color: var(--black);
}

.Phase3Game .submit-button {
  display: flex;
  padding: 0 15px;

}

.Phase3Game .submit-button .submit-icon {
  display: inline-block;
  margin-right: 15px;
  width: 40px;
  height: 40px;

  border: 3px solid var(--black);
  box-shadow: var(--shadow-sm);
  background: #96d12f;
  border-radius: 99px;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: opacity 0.35s;

  position: relative;
  z-index: 1;
}

.Phase3Game .submit-button:not(.disabled) .submit-icon::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 3px;
  background: #000;
  left: 100%;
}

.Phase3Game .submit-button button {
  height: 40px;
  font-size: 1em;
  font-weight: bold;
  border: 3px solid var(--black);
  box-shadow: var(--shadow-sm);
  background: #96d12f;
  color: var(--black);
  border-radius: 99px;
  padding: 5px 15px;

  cursor: pointer;
  transition: background-color .35s, color .35s, opacity 0.35s;

  position: relative;
  z-index: 1;

}

.Phase3Game .submit-button:not(.disabled) button:hover {
  background:var(--black);
  color: #96d12f;
}



.Phase3Game .submit-button.disabled button,
.Phase3Game .submit-button.disabled .submit-icon {
  opacity: 0.5;
  background: rgb(228, 228, 228);
  cursor: auto;
}



.Phase3Game .col-right{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: #f0ebe3;
}

.Phase3Game .col-right .categories{
  flex-grow: 1;
  overflow-y: auto;
}
/*
.Category:first-child .Card.locked .locked-button {
  border: 1px solid  red;
} */