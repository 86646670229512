.PickButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  opacity: 1 !important;
}

.plus-button {
  background: var(--black);
  color: #9bd146;
}
.plus-button:hover {
  background:#9bd146;
  color:var(--black);
}

.x-button {
  background: #fff5e0;
  color: var(--black);
}
.x-button:hover {

  background: var(--black);
  color:#fff5e0;
}

.PickButton-text {
  padding: 0 20px;
  font-size: 1em;
  color: var(--grey4);
  border-radius: 99px;
}

.Card.locked .PickButton-text {

  cursor: help;
  border: 2px solid var(--grey4);
  box-shadow: 4px 4px 0 0px  rgba(0, 0, 0, 0.5);;

  border: 2px solid #000;
  box-shadow: 4px 4px 0 0px #000;
  color: #000;

}

.PickButton .icon { margin-right: 10px;}



.TOO_COMPLEX .PickButton-text { color: var(--red);}
.PICKED_PREV .PickButton-text { color: var(--bcg-green);}
