.Ftue {
  position: relative;
  --padding-left: 150px;
  --padding-right: 120px;
  --left: 200px;
  --right: 200px;
}

/* permet d'éviter le scroll avec la molette sur la partie "highlight" */
.ftue-visible .categories {
  overflow: hidden !important;
}

.Ftue-opaque {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  background: rgba(0, 0, 0, 0.85);
}
.Ftue.with-mentor .Ftue-opaque { background: rgba(0, 0, 0, 0.5); }

.Ftue .ftue-transparent {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 6;
  background: transparent;
  pointer-events: all;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

}


.ftue-transparent.ALLOW_CLICK { pointer-events: none; }

.Ftue .Ftue-content {
  color: #FFF;
  padding: 20px;
  max-width: 40%;

  /* border: 1px solid  red; */

}

.Ftue .Ftue-content.with-highlight {
  position: absolute;

}

.Ftue .Ftue-content h1{
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 35px;

}


.Ftue.with-mentor .Ftue-content {
  position: absolute;
  bottom: 40px;
  left: var(--left);
  right: 30px;
  max-width: none;

}
.Ftue .Ftue-content .text { font-size: 25px; }

.Ftue.with-mentor .Ftue-content h1{
  font-size: 28px;
  text-align: left;
  background: var(--black);
  color: #FFF5E0;
  margin-bottom: 0;
  display: inline-block;
  padding: 15px;

  padding-left: var(--padding-left);
  border-radius: 0 30px 0 0;
  padding-right: 70px;
}

.Ftue.with-mentor .Ftue-content .text {
  background: #FFF5E0;
  color: #000;
  border: 5px solid var(--black);
  box-shadow: var(--shadow);
  text-shadow: none;
  padding: 30px;
  padding-left: var(--padding-left);
  border-radius: 30px;
  text-align: left;
}

.Ftue.with-mentor.Naomi .Ftue-content {
  text-align: right;
  right: var(--right);
  left: 30px;

}

.Ftue.with-mentor.Naomi .Ftue-content h1{
  padding: 15px 70px;
  padding-right: var(--padding-right);

  border-radius: 30px 0 0 0;
}
.Ftue.with-mentor.Naomi .Ftue-content .text {
  padding: 30px;
  padding-right: var(--padding-right);
  /* text-align: right; */
  background: var(--lightblue);
}

.Ftue .ftue-transparent .bb { position: absolute; }

.Ftue .naomi-image,
.Ftue .mentor-image { display: none; }

.Ftue.with-mentor .mentor-image {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}


.Ftue.with-mentor .naomi-image { display: none; }
.Ftue.with-mentor.Naomi .mentor-image { display: none;}
.Ftue.with-mentor.Naomi .naomi-image {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
}


/* .Ftue */
@media screen and (max-width: 1200px) {
  .Ftue {
    --left: 120px;
    --right: 160px;

    /* --padding-right: 90px;
    --padding-left: 90px; */

  }
  .Ftue.with-mentor .mentor-image,
  .Ftue.with-mentor .naomi-image {
    width: 300px;
  }
}